<template>
    <div class="fl-x fl-a-c">
        <div v-if="showImage" class="w-7p mr-lg-3">
            <img src="../../assets/web/dashboard/avatar.jpg" alt=""
                 class="b-rounded img-fluid avatar-img-abs d-lg-block">
        </div>
        <div class="fl-te-c-sm w-100p pr-2 px-lg-0">
            <div>
                <div class="mb-2 heading-text font-inter-bold"><span>Hello, </span><span>{{ name }}</span></div>
                <p v-if="info!==''" class="font-inter-extra-light">{{ info }}</p>
            </div>
            <section class="fl-x fl-a-s">
                <span class="mr-2">
<!--                    <img class="icon-sm" src="../../assets/web/dashboard/icons/bag.png" alt="">-->
                    <img class="icon-sm" src="../../assets/web/dashboard/icons/icons8-bag-24.png" alt="">
                </span>

                <span class="text-4 mr-lg-2 mr-1 fs-lg-0">Role</span>
                <span class="font-inter-regular fs-lg-0" v-if="user.is_superuser">Super User</span>
                <span class="font-inter-regular fs-lg-0" v-else-if="user.is_bank_admin">Bank Admin</span>
                <span class="font-inter-regular fs-lg-0" v-else-if="user.is_bank_user">Bank User</span>
                <span class="font-inter-regular fs-lg-0" v-else>{{ user.employee_role }}</span>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name  : 'HelloMessage',
    props : {
        name : {
            type : String
        },
        user : {
            type : Object
        },
        info : {
            type    : String,
            default : ''
        },
        showImage : {
            type    : Boolean,
            default : false
        }
    }
};
</script>

<style scoped>
    .heading-text {
        font-size: 1.1rem;
    }
</style>
