<template>
    <!--    <div class="z-index-2 cursor-pointer py-3 pt-lg-5 fs&#45;&#45;1-lg" :class="{'b-1': size !=='xs'}">-->
    <div class="z-index-2 py-3 pb-4 pt-lg-5 fs--1-lg pos-r">
        <div class="line-bottom"></div>
        <div class="fl-y fl-a-c bg-white py-3 h-100 pos-r" :class="{'w-80':size ==='sm'}">
            <!--            <div class="ml-auto mt-0 top-0 cursor-ponter" v-if="size === 'lg'" @click="goBack"><span-->
            <!--                class="p-2 bg-primary"><i class="fa fa-chevron-left"></i></span></div>-->
            <div class="w-6re mb-2" :class="{'half-width':size ==='sm' || size ==='xs','w-12':size === 'lg'}">
<!--                <img :src="img" alt="" class="border-rounded img-fluid">-->
                <div :style="{'background-image':'url('+ img + ')'}" class="b-2-primary border-rounded w-h-100 profile-bg"></div>
            </div>
            <p class="font-weight-bold mb-2">{{ name }}</p>
            <p class="font-weight-light m-0 text-center">{{ designation }}</p>
            <div class="">
                <slot name="details"></slot>
            </div>
            <div>
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name  : 'AvatarCard',
    props : {
        name          : { type : String, default : '' },
        designation   : { type : String, default : '' },
        img           : { type : Image },
        size          : { type : String, default : 'sm' },
        type          : { type : String, default : '' },
        backRouteName : { type : String, default : 'Home' },
        detailsPath   : { type : String, default : '/' }
    },
    methods : {
        goBack () {
            this.$router.go(-1);
            this.$router.push({ name : this.backRouteName });
            if (this.type === 'board') {
                this.$router.push({ name : 'About' });
            } else {
                this.$router.push({ name : 'History' });
            }
        },
        openMemberPage () {
            if (this.size === 'sm') {
                const routePath = this.detailsPath + this.name.replace(/\s+/g, '');
                this.$router.push({ path : routePath, params : { name : this.name.trim() } });
            }
        }
    }
};
</script>
<style scoped>
.border-rounded {
    border-radius: 50%;
}

.bb-1-primary {
    border-bottom: 0.3em solid var(--color-primary);
}

.h-100 {
    height: 100%;
}

.w-12 {
    width: 30%;
}

.w-40 {
    width: 40%;
}

.card-banner {
    width: 100%;
    height: 8.5rem !important;
    background-color: var(--color-primary);
    border-radius: 0.8rem;
}

.avatar-img-abs {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
}

.line-bottom {
    position: absolute;
    /*right: 0.5rem;*/
    /*left: 0.5rem;*/
    bottom: 0.7rem;
    bottom: 0.7rem;
    width: 100%;
    height: 1px;
    background-color: rgba(128, 128, 128, 0.03);
}

.w-6re {
    /*width: 4.2 rem;*/
    width: 5.8rem;
    height: 5.8rem;
}

.profile-bg {
    overflow: hidden;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.b-2-primary{
    /*border: 1.4px solid var(--color-primary);*/
    border: 2px solid rgba(32,75,184,0.8);
}
</style>
