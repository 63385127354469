<template>
    <div class="z-index-2 pos-r fs--1-lg line-bottom-100">
        <div class="line-bottom"></div>
        <div class="card-banner"></div>
        <div class="fl-y fl-a-c bg-white py-3 h-100 px-3 pos-r" :class="{'w-80':size ==='sm'}">
            <div class="ml-auto mt-0 top-0 cursor-ponter" v-if="size === 'lg'"><span
                class="p-2 bg-primary"><i class="fa fa-chevron-left"></i></span></div>
            <div class="avatar-w avatar-img-abs mb-2" :class="{'w-12':size ==='sm','w-12':size === 'lg'}">
                <img :src="img" alt="" class="border-rounded img-fluid">
            </div>
            <p class="font-weight-bold pt-5 mb-2">{{ name }}</p>
            <p class="font-weight-light m-0 text-center">{{ designation }}</p>
            <div class="">
                <slot name="details"></slot>
            </div>
            <div>
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name  : 'SuperAdminAvatarCard',
    props : {
        name          : { type : String, default : '' },
        designation   : { type : String, default : '' },
        img           : { type : String, default : null },
        size          : { type : String, default : 'sm' },
        type          : { type : String, default : '' },
        backRouteName : { type : String, default : 'Home' },
        detailsPath   : { type : String, default : '/' }
    },
    methods : {
        goBack () {
            this.$router.go(-1);
            this.$router.push({ name : this.backRouteName });
            if (this.type === 'board') {
                this.$router.push({ name : 'About' });
            } else {
                this.$router.push({ name : 'History' });
            }
        },
        openMemberPage () {
            if (this.size === 'sm') {
                const routePath = this.detailsPath + this.name.replace(/\s+/g, '');
                this.$router.push({ path : routePath, params : { name : this.name.trim() } });
            }
        }
    }
};
</script>
<style scoped lang="scss">
.border-rounded {
    border-radius: 50%;
}

.bb-1-primary {
    border-bottom: 0.3em solid var(--color-primary);
}

.h-100 {
    height: 100%;
}

.avatar-w {
    /*width: 30%;*/
    width: 5.4rem;
    @media (min-width: 1400px) {
        width:7.4rem;
    }
}

.card-banner {
    width: 100%;
    height: 8.5rem !important;
    /*background-color: var(--color-primary);*/
    background: url("../../assets/web/homee/pattern.png");
    border-radius: 0.8rem;
}

.avatar-img-abs {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
}

</style>
