<template>
    <div class="layout-resp pt-lg-3" :class="{'pos-ft pos-f-t':fixedTop,'header-sm':size ==='sm', 'bb-light':borderBottom}">
        <hello-message class="w-100" :show-image="true" :name="currentUser.name"
                       :user="currentUser"></hello-message>
        <div class="px-2 pt-3 pb-2 pt-2 fl-x-cr">

            <div class="fl-x fl-a-c">
                <!--            <div v-if="currentUser.is_superuser" class="logout-icon border-rounded cursor-pointer pos-r pr-3"  @click="$router.push('/logout/')">-->
                <!--                <div class="line-right"></div>-->
                <!--                <img src="../assets/web/icons/icons8-shutdown-16.png" alt="">-->
                <!--            </div>            -->
                <div v-if="currentUser.is_superuser" class="cursor-pointer pos-r pr-3" @click="$router.push('/logout/')">
                    <div class="line-right"></div>
                    <img src="../assets/web/homee/logout.png" alt="" style="width: 24px;height: 24px;">
                </div>
                <helpline-info-card v-if="!currentUser.is_superuser" heading="Helpline" :border-right="true"
                                    :content="currentUser.helpline"></helpline-info-card>

                <notification-button link="/notifications-page/" :notification="currentUser.notification_count"
                                     class="ml-3"></notification-button>
                <div v-if="!currentUser.is_superuser" class="cursor-pointer pos-r ml-3 pr-3" @click="$router.push('/logout/')">
                    <!--                <div class="line-right"></div>-->
                    <img src="../assets/web/homee/logout.png" alt="" style="width: 24px;height: 24px;">
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import HelloMessage from '@components/dashboard/HelloMessage';
import HelplineInfoCard from '@components/dashboard/HelplineInfoCard';
import NotificationButton from '@components/NotificationButton';
import { mapGetters } from 'vuex';

export default {
    name : 'HeaderComponent',

    computed : { ...mapGetters(['currentUser']) },

    components : { NotificationButton, HelplineInfoCard, HelloMessage },

    props : {

        fixedTop : {
            type    : Boolean,
            default : true
        },
        borderBottom : {
            type    : Boolean,
            default : true
        },

        size : {
            type    : String,
            default : 'lg'
        }

    },
    data () {
        return {
            userType : 'super-admin' // for testing
        };
    }
};
</script>

<style scoped lang="scss">
    .pos-f-t {
        position: fixed;
        top: 0;
        left: 0;
        @media (min-width: 992px) {
            left: 16rem;
        }
        //width: 100%;
        z-index: 999 !important;
        background-color: var(--bg-2);
    }

    .header-sm {
        position: fixed;
        z-index: 999;
        background: var(--bg-2);
        /* right: 8%; */
        left: 0;
        @media (min-width: 992px) {
            left: 18%;
            //width: 60.57%;
            //width: 54rem;
            width: 54.7rem;
            //width: 51.7rem;
        }
        @media (min-width: 1400px) {
            left: 12%;
            //width: 79.4rem;
            width: 74.4rem !important;
        }
    }

    .logout-icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
            content: '';
            position: absolute;
            //left: 0;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            //background-color: var(--color-primary);
            background-color: #3a63f0;
            z-index: -1;
        }
    }

    .layout-resp {
        display: flex;
        flex-direction: column;
        @media (min-width: 768px) and (max-width: 1024px) {
            flex-direction: row;
            justify-content: space-between;
        }
        @media (min-width: 992px) {
            flex-direction: row;
            justify-content: space-between;
        }
    }
</style>
