<template>
    <div class="fl-x fl-a-c pos-r" :class="{'pr-3':borderRight}">
        <!--        <div class="w-2 text-center fl-x-cc mb-3 mb-lg-0"><img src="../assets/mobile/homee/why.png" alt=""></div>-->
        <div class="line-right" v-if="borderRight"></div>
        <div class="text-right min-w-lg-14re sm-fl-x fl-order-2 fl-order-lg-1">
            <p class="font-inter-regular text-4 fl-lg-0 fs-lg--1 mb-0 mr-2 mr-lg-0">{{ heading }}</p>
            <a :href="'tel:' + content"
               class="text-decoration-none fs-lg--1 font-inter-semi-bold fl-lg-0 mt-sm-2">{{ mobileAreaCode }}
                {{ content }}</a>
            <!--            <p class="font-inter-semi-bold fl-lg-0">{{ content }}</p>-->
        </div>
        <div class="border-rounded z-index-1 pos-r text-center text-primary fl-x-cc mb-lg-0 ml-3 mr-2 mr-lg-0 fl-order-1 fl-order-lg-2">
            <!--            <i class="fa fa-phone"></i>-->
            <a :href="'tel:' + content" class="text-decoration-none font-inter-semi-bold fl-lg-0">
                <img src="../../assets/web/dashboard/icons/phone-24.png" class="" alt="">
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name  : 'HelplineInfoCard',
    props : {
        heading : {
            type    : String,
            default : ''
        },
        content : {
            type    : String,
            default : ''
        },
        img : {
            type : Image
        },
        borderRight : {
            type    : Boolean,
            default : false
        }
    }
};
</script>

<style scoped lang="scss">
.border-rounded {
    border-radius: 50%;
}

.w-20 {
    width: 20% !important;
    height: 20% !important;
}

.w-2 {
    width: 3.5rem;
    height: 3.5rem;
    @media (min-width: 992px) {
        width: 6.5rem;
        height: 3.5rem;
    }
}

.bb-1-primary {
    border-bottom: 0.3em solid var(--color-primary);
}

.fl-x-res {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: 992px) {
        flex-direction: row !important;
    }
}

.text-center-res {
    text-align: center;
    @media (min-width: 992px) {
        text-align: left;
    }
}

.round-before {
    ::before {
        content: '';
        position: absolute;
        bottom: 0;
        top: 0;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: var(--color-secondary);
        z-index: -999 !important;
    }
}

.min-w-lg-14re {
    @media (min-width: 992px) {
        min-width: 14rem !important;
    }
}
</style>
