<template>
    <div class="notification-contain cursor-pointer" @click="gotoLink">
        <img src="../assets/web/dashboard/icons/bells.png" class="icon-img" alt="">
        <span class="notification-bullet ml-1 p-1 bg-secondary">{{ notification }}</span>
    </div>
</template>

<script>

export default {
    name : 'NotificationButton',

    props : {
        notification : {
            type    : Number,
            default : 0
        },

        link : {
            type    : String,
            default : ''
        }
    },

    methods : {

        gotoLink () {
            if (this.link) {
                this.$router.push({ path : this.link });
            } else {
                alert('There is no link to redirect');
            }
        }
    }
};
</script>

<style scoped lang="scss">
.notification-contain {
    position: relative;

    .notification-bullet {
        position: absolute;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -3px;
        right: 2px;
        font-size: 0.5rem;
        background-color: var(--color-danger) !important;
        color: white !important;
        border-radius: 50%;
    }
}
.icon-img {
    width: 24px;
    height: auto;
}
</style>
